import React, { useEffect, useState, useRef } from 'react';
import { scroller, ScrollElement } from 'react-scroll';
import styled from 'styled-components';
import { decode } from 'js-base64';

import HeaderBar from 'components/header-bar';
import BottomSheet from 'components/bottom-sheet';
import Button from 'components/button';
import AddressSearch from 'components/address-search/index.jsx';
import GoogleMapFrame from 'components/google-map';

import { useAPI } from 'utils/api';
import { showLoading, dismissLoading } from 'utils/loading';
import { STORE_TYPE } from 'utils/structure';
import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';

import imageLocationPoint from 'images/icons/i-location-point.svg';
import Check from 'images/icons/round-check-fill.svg';
import twentyFourHourIcon from 'images/icons/ic-24h.svg';

const AddressWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #5fd2da;
  padding: 13px 5.15px 13px 5.15px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  .address {
    display: flex;

    img {
      margin-right: 5px;
    }

    .address-input {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      background: transparent;
      border: 0;
      outline: none;
    }
  }

  .store {
    font-weight: bold;
    flex: 0 0 100%;
    color: #333333;
    margin-left: 29px;
  }
`;

const ReceiverAddrInput = ({
  marginBottom,
  placeholder,
  type,
  text,
  onChange,
  onChangeValue,
  maxLength,
  message,
  showMessage = false,
}) => {
  const [textValue, setTextValue] = useState(text);

  const onChangeHandle = (event) => {
    setTextValue(event.target.value);
    if (onChange !== undefined && typeof onChange === 'function') {
      onChange(event);
      return;
    }
    if (onChangeValue !== undefined && typeof onChangeValue === 'function') {
      onChangeValue(event.target.value);
      return;
    }
  };

  useEffect(() => {
    setTextValue(text);
  }, [textValue, text]);

  return (
    <>
      <input
        className="input"
        maxLength={maxLength}
        type={type || 'text'}
        placeholder={placeholder}
        value={textValue}
        onChange={onChangeHandle}
      />

      {showMessage ? <div className="message">{message}</div> : undefined}
      <style jsx>
        {`
          input[type='text'] {
            width: 100%;
            background: #ffffff;
            border: 1px solid #5fd2da;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 14px 12px 13px 12px;
            margin-bottom: ${marginBottom ? `${marginBottom}px` : '0'};
            &::placeholder {
              opacity: 0.5;
            }
          }
          .message {
            font-family: Noto Sans TC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #5fd2da;
            margin-top: ${marginBottom ? `-${marginBottom}px` : '0'};
            margin-bottom: ${marginBottom ? `${marginBottom}px` : '0'};
          }
        `}
      </style>
    </>
  );
};

const StoreRadioGroup = ({
  data,
  onChangeStore,
  selectStore,
  shippingModel,
  handleStoreRadioScrollTo,
}) => {
  return (
    <>
      <div
        className="store-radio-group"
        id="store-radio-group"
        name="store-radio-group"
      >
        {data.map((item, index) => (
          <StoreRadioElement
            key={index}
            data={item}
            onChangeStore={onChangeStore}
            selectStore={selectStore}
            shippingModel={shippingModel}
            handleStoreRadioScrollTo={handleStoreRadioScrollTo}
          />
        ))}
      </div>
      <style jsx>{`
        .store-radio-group {
          display: flex;
          gap: 11px;
          padding-left: 11px;
          overflow-x: auto;
          padding-bottom: 8px;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      `}</style>
    </>
  );
};
const StoreRadio = ({
  data,
  onChangeStore,
  selectStore,
  shippingModel,
  handleStoreRadioScrollTo,
}) => {
  return (
    <>
      <label
        key={data.id}
        className="store-radio"
        htmlFor={data.id}
        id={`store-item-${data.id}`}
        name={`store-item-${data.id}`}
        onClick={() => handleStoreRadioScrollTo(data.id)}
      >
        <input
          className="store-input"
          type="radio"
          id={data.id}
          name="store"
          onChange={onChangeStore}
          checked={selectStore === data.id}
          placeholder={data.name}
        />
        <div className="check-input">
          <img src={Check} alt="checked" className="checked" />
        </div>
        <p className="store-name">{data.name}</p>
        {data['24h'] ? (
          <img
            className="hr-icon"
            src={twentyFourHourIcon}
            alt="twentyFourHourIcon"
          />
        ) : (
          <></>
        )}
        <p className="store-type">{STORE_TYPE[data.c_sst_type]}</p>
        {shippingModel === '1' ? (
          <p className="store-info">外送費${data.shipmentCost}</p>
        ) : undefined}
        <p className="store-info">{data.estimationTime}</p>
      </label>
      <style jsx>{`
        .store-radio {
          margin-right: 10px;
          position: relative;
          display: inline-block;
          width: 113px;
          padding: 10px 9px 5px 7px;
          border-radius: 16px;
          background-color: #fff;
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
          flex-shrink: 0;
        }
        .hr-icon {
          margin-right: 5px;
        }
        .store-type {
          display: inline-block;
          color: #999;
          font-size: 12px;
          line-height: 17px;
        }
        .store-input {
          display: none;
        }
        .check-input {
          position: absolute;
          right: 9px;
          top: 9px;
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 100%;
          border: 1px solid #e3e3e3;
        }
        .checked {
          display: none;
        }
        .store-input:checked ~ .check-input {
          width: 14px;
          height: 14px;
          border: none;
          .checked {
            display: block;
          }
        }
        .store-top {
        }
        .store-name {
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 3px;
        }
        .store-info {
          color: #999;
          font-size: 12px;
          line-height: 17px;
        }
      `}</style>
    </>
  );
};
const StoreRadioElement = ScrollElement(StoreRadio);

// mode
// add:  新增
// edit: 編輯
const ReceiverManage = ({ mode, location }) => {
  const api = useAPI();

  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [addressDefault, setAddress] = useState('');
  const [addrFloor, setAddrFloor] = useState('');
  const [addressTag, setAddressTag] = useState('');
  const [elevatorValue, setElevatorValue] = useState('');
  const [receiveTypeValue, setReceiveTypeValue] = useState('');
  const [note, setNote] = useState('');

  const [receiveTypeOptions, setReceiveTypeOptions] = useState([]);
  const [showAddressSearch, setShowAddressSearch] = useState(false);
  const [update, setUpdate] = useState(false);

  const [locationMap, setLocation] = useState({ lat: 25.04, lng: 121.51 });
  const [nearByStores, setStoreList] = useState([]);
  const [district, setDistrict] = useState('');
  const [storeId, setStoreId] = useState('');
  const [storeName, setStoreName] = useState('');
  const [postalCode_3, setPostalCode_3] = useState('');

  const dispatchData = useRef({});
  const isCurrentAddress = useRef(false);

  const [canSave, setCanSave] = useState(false);

  const AddressStatus = {
    UPDATE_ADDRESS: 0,
    CURRENT_ADDRESS: 1,
    CURRENT_AND_CHANGE_STORE: 2,
  };

  const handleStoreRadioScrollTo = (id) => {
    scroller.scrollTo(`store-item-${id}`, {
      duration: 1000,
      delay: 100,
      smooth: true,
      containerId: 'store-radio-group',
      offset: 0,
      horizontal: true,
    });
  };

  const onChangeStore = (event) => {
    setStoreId(event.currentTarget.id);
    setStoreName(event.currentTarget.placeholder);
  };

  const validData = (param) => {
    let isValid = true;
    if (param.fullName === '') {
      alert('請輸入收件人姓名');
      isValid = false;
      return isValid;
    }

    if (param.phone === '') {
      alert('請輸入手機電話');
      isValid = false;
      return isValid;
    }

    if (param.phone.length !== 10) {
      alert('手機格式輸入錯誤');
      isValid = false;
      return isValid;
    }

    if (!phoneFormatValid(param.phone)) {
      alert('手機格式輸入錯誤');
      isValid = false;
      return isValid;
    }

    if (param.address === '') {
      alert('請輸入收件人地址');
      isValid = false;
      return isValid;
    }

    if (param.deliveryPointValue === '') {
      alert('請選擇配送方式');
      isValid = false;
      return isValid;
    }

    if (elevatorValue === '') {
      alert('請選擇有無電梯');
      isValid = false;
      return isValid;
    }

    if (param.defaultStoreId === '') {
      alert('請選擇預設配送門店');
      isValid = false;
      return isValid;
    }

    return isValid;
  };

  const submit = (event) => {
    event.preventDefault();

    const mainData = {
      fullName: name,
      lastName: '',
      phone: mobile,
      tag: addressTag,
      deliveryNote: note,
      isDefault: true,
      deliveryPointValue: receiveTypeValue,
      address:
        (addressDefault === '' ? '' : addressDefault) +
        (addrFloor === '' ? '' : addrFloor),
      hasElevator: elevatorValue === '1' ? true : false,
      defaultStoreId: storeId,
      defaultStoreName: storeName,
      postalCode_3: postalCode_3,
    };

    if (
      !validData({
        ...mainData,
        deliveryIsElevator: mainData.hasElevator,
      })
    ) {
      return false;
    }

    showLoading();

    const parameters = {
      ...mainData,
      deliveryIsElevator: mainData.hasElevator,
      district: district,
    };

    if (mode === 'add') {
      api
        .newAddress(parameters)
        .then((res) => {
          dismissLoading();
          alert('新增成功');
          callAppFunc('backHome', {});
        })
        .catch((error) => {
          dismissLoading();
          console.log(error);
        });
    } else {
      updateAddressHandle(parameters);
    }
  };

  const updateAddressHandle = (mainData = {}) => {
    const addressId = location.state.data.data.addressId;

    confirmChangeStore(mainData)
      .then((status) => {
        api
          .updateAddress({ ...mainData, addressId })
          .then((res) => {
            if (!res) {
              return;
            }
            switch (status) {
              case AddressStatus.CURRENT_AND_CHANGE_STORE:
                api
                  .changeStore({ storeId: mainData.defaultStoreId })
                  .then((res) => {
                    dismissLoading();
                    if (!res) {
                      return;
                    }

                    saveDispatchData(mainData);
                    endPage();
                  })
                  .catch((error) => {
                    dismissLoading();
                  });
                return;
              case AddressStatus.CURRENT_ADDRESS:
                saveDispatchData(mainData);
                break;
              default:
                break;
            }
            dismissLoading();
            endPage();
          })
          .catch((err) => {
            dismissLoading();
          });
      })
      .catch((err) => {
        dismissLoading();
        console.log(err);
      });
  };

  const endPage = () => {
    alert('修正完成!');
    callAppFunc('backHome', { needRefresh: 'true' });
  };

  const saveDispatchData = (mainData = {}) => {
    // 更新收件人資訊
    dispatchData.current = {
      ...dispatchData.current,
      receiverName: mainData.fullName,
      receiverPhone: mainData.phone,
      receiverAddress: mainData.address,
      receiverElevator: mainData.hasElevator ? '1' : '0',
      receiverReceive: mainData.deliveryPointValue,
      receiverAddressRemark: mainData.deliveryNote,
      storeId: mainData.defaultStoreId,
      storeName: mainData.defaultStoreName,
    };
    callAppFunc('saveDispatchData', {
      data: JSON.stringify(dispatchData.current),
    });
    localStorage.removeItem('TmpCartData');
  };

  const confirmChangeStore = (mainData) => {
    return new Promise((resolve, reject) => {
      console.log('current storeId =', dispatchData.current.storeId);
      console.log('default storeId =', mainData.defaultStoreId);
      console.log('isCurrentAddress =', isCurrentAddress.current);
      // dismissLoading();
      // return;
      if (
        mainData.defaultStoreId !== dispatchData.current.storeId &&
        isCurrentAddress.current
      ) {
        dismissLoading();
        // 換店，並且是當前地址
        confirm(
          '切換出貨地址或選擇其他門店，原購物車內商品將會清除',
          '更換門店'
        )
          .then(() => {
            showLoading();
            resolve(AddressStatus.CURRENT_AND_CHANGE_STORE);
          })
          .catch(() => {
            reject("don't change store");
          });
        return;
      }

      if (isCurrentAddress.current) {
        resolve(AddressStatus.CURRENT_ADDRESS);
        return;
      }

      resolve(AddressStatus.UPDATE_ADDRESS);
    });
  };

  const getOptions = () => {
    api
      .getReceiveType()
      .then((res) => {
        setReceiveTypeOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const phoneFormatValid = (phone, isEdit = true) => {
    if ((!isEdit && phone === undefined) || phone === '') {
      return true;
    }

    var mobileRegex = /^09\d{8}$/;
    var result = mobileRegex.test(phone);

    return result;
  };

  const handleAddressChange = (addr) => {
    getNearStore(null, null, addr, false);
  };

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    switch (dataJson.fnName) {
      case 'sendDispatchData':
        const data = JSON.parse(decode(dataJson.params.data));
        dispatchData.current = data;
        getReceiverList()
          .then((res) => {
            const addr = res.rows.find(
              (item) => item.addressId === dispatchData.current.addressId
            );
            console.log(
              'dispatchData.current.addressId',
              dispatchData.current.addressId
            );
            console.log('address', res.rows);

            // 如果addr找到
            if (addr) {
              console.log('addr', addr);
              isCurrentAddress.current = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        break;
      case 'sendGPS':
        setLocation({
          lat: Number(dataJson.params.latitude),
          lng: Number(dataJson.params.longitude),
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getOptions();

    WebFn({ callback: jsBridgeCallback });

    if (mode !== 'add') {
      getAddressDetail();
      callAppFunc('fetchDispatchData', {});
    } else {
      callAppFunc('fetchGPS', {});
    }

    setUpdate(true);
  }, []);

  const getReceiverList = () => {
    return new Promise((resolve, reject) => {
      api
        .getAddress()
        .then((res) => {
          if (!res) {
            reject('get address fail');
            return;
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getNearStore = (lat, lng, addr, hasStore) => {
    showLoading();
    setCanSave(false);
    api
      .getStoreByLocation(lat, lng, addr)
      .then((res) => {
        dismissLoading();
        if (!res) {
          // 檢查失敗清空地址、門店與門店列表
          setAddress(addr);
          setStoreId('');
          setStoreName('');
          setStoreList([]);
          return;
        }

        setCanSave(true);

        setLocation({
          lat: res.query.latitude,
          lng: res.query.longitude,
        });

        setStoreList(res.rows);
        setDistrict(res.query.district);

        // 3碼郵遞區號
        if (mode === 'add') setPostalCode_3(res.query.postalCode_3);

        // 沒有預設門店, 取第一個門店
        if (!hasStore) {
          handleStoreRadioScrollTo(res.rows[0].id);
          setStoreId(res.rows[0].id);
          setStoreName(res.rows[0].name);
        }
      })
      .catch((error) => {
        dismissLoading();
        console.log('get location by server exception =', error);
      });
  };

  const getAddressDetail = () => {
    const addressId = location.state.data.data.addressId;

    api
      .getAddressDetail(addressId)
      .then((res) => {
        setName(res.fullName);
        setMobile(res.phone);
        setNote(res.deliveryNote);
        setAddressTag(res.tag);
        setElevatorValue(res.deliveryIsElevator ? '1' : '0');
        setReceiveTypeValue(res.deliveryPointValue);
        setAddress(res.adjustedAddress);
        setAddrFloor(res.floor);
        setStoreId(res.defaultStoreId);
        setStoreName(res.defaultStoreName);
        setPostalCode_3(res.postalCode_3);

        if (res.defaultStoreId !== '') {
          setStoreId(res.defaultStoreId);
          setStoreName(res.defaultStoreName);
          getNearStore(null, null, res.adjustedAddress, true);
          setTimeout(() => {
            handleStoreRadioScrollTo(res.defaultStoreId);
          }, 1000);
        } else {
          getNearStore(null, null, res.adjustedAddress, false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <HeaderBar
        title={`收件人 ${mode === 'add' ? '新增' : '資料編輯'}`}
        goBack={() => {
          callAppFunc('backHome', {});
        }}
      />

      {update ? (
        <form onSubmit={submit}>
          <div id="map">
            <GoogleMapFrame location={locationMap} zoom={16} />
          </div>
          <div className="store-list">
            <StoreRadioGroup
              shippingModel={'1'}
              data={nearByStores}
              onChangeStore={onChangeStore}
              selectStore={storeId}
              handleStoreRadioScrollTo={handleStoreRadioScrollTo}
            />
          </div>
          <div className="form">
            <h2>收件人</h2>
            <ReceiverAddrInput
              placeholder="請輸入姓名"
              marginBottom={12}
              text={name}
              onChangeValue={(v) => setName(v)}
            />
            <h2>電話</h2>
            <ReceiverAddrInput
              placeholder="請輸入電話"
              marginBottom={12}
              maxLength={10}
              text={mobile}
              onChangeValue={(v) => setMobile(v)}
              message="手機號碼格式錯誤"
              showMessage={!phoneFormatValid(mobile, true)}
            />
            <h2>地點</h2>
            <AddressWrapper>
              <div className="address">
                <img src={imageLocationPoint} />
                <div
                  className="address-input"
                  placeholder="請輸入地址"
                  onClick={() => setShowAddressSearch(true)}
                >
                  {addressDefault}
                </div>
              </div>
              <div className="store">{storeName}</div>
            </AddressWrapper>

            <h2>樓層房號</h2>
            <ReceiverAddrInput
              placeholder="請輸入樓層房號"
              text={addrFloor}
              marginBottom={12}
              onChangeValue={(v) => setAddrFloor(v)}
            />
            <h2>地址標籤</h2>
            <ReceiverAddrInput
              label="地址標籤"
              placeholder="地址標籤"
              text={addressTag}
              marginBottom={12}
              onChangeValue={(v) => setAddressTag(v)}
            />
            <div className="radios">
              <h2 style={{ display: 'inline', paddingRight: '10px' }}>電梯</h2>
              <input
                type="radio"
                id="y"
                name="elevator"
                value="1"
                checked={'1' === elevatorValue}
                onChange={(e) => {
                  setElevatorValue(e.target.value);
                }}
              />
              <label htmlFor="y">有</label>
              <input
                type="radio"
                id="n"
                name="elevator"
                value="0"
                checked={'0' === elevatorValue}
                onChange={(e) => {
                  setElevatorValue(e.target.value);
                }}
              />
              <label htmlFor="n">無</label>
            </div>
            <div className="radios">
              <h2>簽收</h2>
              {receiveTypeOptions &&
                receiveTypeOptions.map((item, index) => (
                  <div key={`div-receive-${index}`}>
                    <input
                      type="radio"
                      id={`receive-${index}`}
                      name="sign"
                      value={item.value}
                      checked={item.value === receiveTypeValue}
                      onChange={(e) => {
                        setReceiveTypeValue(e.target.value);
                      }}
                    />
                    <label htmlFor={`receive-${index}`}>{item.name}</label>
                  </div>
                ))}
              <p className="notice">
                如果您的樓層有安全管制，請通知管理室協助簽收。
              </p>
              <h2 className="memo">地址備註</h2>
              <textarea
                className="note"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                placeholder="公司大樓名稱、其他地址備註"
              ></textarea>
            </div>
          </div>

          <div style={{ display: showAddressSearch ? 'none' : 'block' }}>
            <BottomSheet persistant noTitle noRounded>
              <Button
                disabled={!canSave}
                gray={!canSave}
                text={`${mode === 'add' ? '新增' : '修改'}`}
                type="submit"
              />
            </BottomSheet>
          </div>
        </form>
      ) : undefined}

      <AddressSearch
        isShow={showAddressSearch}
        setShowblock={() => setShowAddressSearch(false)}
        setAddress={setAddress}
        setAddrFloor={setAddrFloor}
        handleAddressChange={handleAddressChange}
        detailAddress={addressDefault}
      />

      <style jsx>{`
        #map {
          width: 100%;
          height: 204px;
          background-color: #aaa;
          position: relative;
        }
        .form {
          position: relative;
          padding: 10px 18px 120px 22px;
        }

        h2 {
          padding-bottom: 8px;
        }
        input + h2 {
          padding-top: 11px;
        }

        .radios {
          padding-top: 12px;
          .notice {
            padding-top: 9px;
            font-size: 14px;
            line-height: 20px;

            color: #000000;
          }
        }
        input[type='radio'] {
          display: none;
        }

        input[type='radio'] + label {
          position: relative;
          font-size: 14px;
          line-height: 20px;
          padding-left: 26px;
          color: #000000;
          & ~ label {
            margin-left: 14px;
          }
        }
        input[type='radio'] + label::before {
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          background-color: #ffffff;
          border: solid 1px #d2d2d2;
          border-radius: 50%;
          box-sizing: border-box;
        }
        input[type='radio']:checked + label::before {
          border: solid 6px #5fd2da;
        }
        .memo {
          padding-top: 11px;
        }
        .note {
          box-sizing: border-box;
          width: 100%;
          min-height: 70px;
          resize: none;
          border: none;
          outline: none;
          font-size: 14px;
          line-height: 20px;
          background: #ffffff;
          border: 1px solid #5fd2da;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 14px 12px 13px 12px;
          color: rgb(51, 51, 51);
          &::placeholder {
            color: rgba(51, 51, 51, 0.5);
          }
        }
        .store-list {
          overflow-x: scroll;
          margin-top: -64px;
          &::-webkit-scrollbar {
            display: none;
          }
        }

        .address-block {
          height: 47px;
          background: #ffffff;
          border: 1px solid #5fd2da;
          box-sizing: border-box;
          padding: 0 12.5px 0 5.15px;
          border-radius: 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          .address {
            flex-grow: 1;
            padding: 0 5px;
            color: #333333;
            .addressInput {
              width: 95%;
              background: transparent;
              border: 0;
              outline: none;
              word-break: break-all;
            }
          }

          .store {
            font-weight: bold;
            color: #333333;
          }
        }
      `}</style>
    </>
  );
};

export default ReceiverManage;
